import { createRouter, createWebHistory } from 'vue-router'
import app from '../App.vue'
import loginSocial from '../views/login-social/login-social.vue'
import home from '../views/home/home.vue'
import signUp from '../views/sign-up/sign-up.vue'
import codeValidation from '../views/code-validation/CodeValidation.vue'
import logout from '../views/logout/logout.vue'
import confirmAccount from '../views/confirm_account/confirm_account.vue'
import history from '../views/history/history.vue'
import campaign from '../views/campaign/campaign.vue'
import maps from '../views/maps/maps.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'app',
      component: app
    },
    {
      path: '/home',
      name: 'home',
      component: home
    },
    {
      path: '/sign-up',
      name: 'sign_up',
      component: signUp
    },
    {
      path: '/login-social',
      name: 'login_social',
      component: loginSocial
    },
    {
      path: '/code-validation',
      name: 'code_validation',
      component: codeValidation
    },
    {
      path: '/logout',
      name: 'logout',
      component: logout
    },
    {
      path: '/history',
      name: 'history',
      component: history
    },
    {
      path: '/confirm-account/:code',
      name: 'confirm_account',
      component: confirmAccount
    },
    {
      path: '/vw/:token?',
      name: 'campaign',
      component: campaign
    },
    {
      path: '/maps',
      name: 'maps',
      component: maps
    },
    {
      path: '/history',
      name: 'history',
      component: history
    }
  ]
})

export default router
