import axios from 'axios'
import PriceTableModal from '@/components/PriceTableModal.vue'
import AppVersion from '@/components/AppVersion.vue'
import sendGtag from '@/services/sendGtags'

const GTAG_SEND_ACTIVATION_MAIL = 'send_activation_mail'

export default {
  name: 'sign_up',
  components: {
    PriceTableModal,
    AppVersion
  },
  data() {
    return {
      errors: [],
      message: null,
      input: {
        email: '',
        userTerms: false
      },
      showPriceTableModal: false
    }
  },
  computed: {},
  mounted() {
    if (this.$route.query.message) {
      this.message = this.$route.query.message
    }
  },
  methods: {
    handleShowPriceTableModal(data) {
      this.showPriceTableModal = data
    },
    signUp: function (e) {
      const validateForm = (input) => {
        this.errors = []
        this.message = null

        const { email: mail, userTerms } = input
        const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

        if (mailformat.test(mail) === false) {
          this.errors.push({ email: ['sign_up.invalid_email'] })
        }
        if (!mail) {
          this.errors.push({ email: ['sign_up.required_email'] })
        }
        if (!userTerms) {
          this.errors.push({ userTerms: ['sign_up.mandatory_user_terms'] })
        }
        return this.errors.length === 0
      }

      if (!validateForm(this.input)) {
        e.preventDefault()
        return
      }

      const { email } = this.input

      axios
        .post(import.meta.env.VITE_MOBILE_API + 'customer/register', { email })
        .then((res) => {
          if (res.data.success === true) {
            sendGtag(GTAG_SEND_ACTIVATION_MAIL)
            alert(
              'Verifique seu email para acessar sua conta. Se não encontrado, verifique a pasta de spam.'
            )
            this.$router.push('/code-validation')
          }

          this.message = res.data.message
        })
        .catch((error) => {
          this.message = error
        })

      e.preventDefault()
    }
  }
}
