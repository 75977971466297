export default {
  rentProcessingStart() {
    this.cleanPBoxProps()
    this.selectBigBoxComponent()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.start.text')
    this.showPBoxSpinner = true
  },
  rentProcessingVerifyTerminal() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.verify.text')
    this.showPBoxSpinner = true
  },
  rentProcessingTerminalFar() {
    this.cleanPBoxProps()
    ;(this.showPBox = true), (this.pBoxTitle = this.$t('home.take.take_battery'))
    this.pBoxText = this.$t('home.take.far')
    this.showPBoxCancelButton = true
    this.pBoxCancelButtonText = this.$t('home.take.cancel')
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.confirm')
    this.pBoxButtonConfirmation = () => this.creditReservation()
    this.pBoxButtonCancel = this.handleTerminalFarCancel
  },
  rentProcessingCancelByUserFar() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.cancel.text')
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('rent_processing.cancel.button')
    this.pBoxButtonConfirmation = this.handleClosePBox
    this.pBoxSecondsToClose = 5
    this.pBoxClose = this.handleClosePBox
  },
  rentProcessingPaymentPermission() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('home.take.credit_reservation')
    this.showPBoxCancelButton = true
    this.pBoxCancelButtonText = this.$t('home.take.cancel')
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.confirm')
    this.showCheckboxPBox = true
    this.pBoxCheckBoxText = this.$t('home.take.dont_ask')
    this.pBoxCheckBoxValue = () =>
      (this.creditCardReservationAlways = !this.creditCardReservationAlways)
    this.pBoxShowLink = true
    this.handleOpenTable = this.scrollToPriceTable
    this.pBoxButtonConfirmation = () => this.completeCreditReservation(true)
    this.pBoxButtonCancel = () => this.completeCreditReservation(false)

    this.showPriceTable = false
  },
  rentProcessingPayment() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.payment.text')
  },
  rentProcessing() {
    this.cleanPBoxProps()
    this.showDemonstration = true
  },
  rentProcessingError(error) {
    let error_validated
    if (error) {
      error_validated = error
    } else {
      error_validated = ''
    }
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('rent_processing.error.title')
    this.pBoxText = this.$t('rent_processing.error.text') + error_validated
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.understand')
    this.pBoxButtonConfirmation = this.handleClosePBox

    this.pointName = null
  },
  rentProcessingSucess(message) {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = message
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.confirm')
    this.pBoxButtonConfirmation = this.handleClosePBox
    this.pBoxSecondsToClose = this.pointName = null
  },
  rentProcessingCampaingPermission() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('home.take.campaign')
    this.showPBoxCancelButton = true
    this.pBoxCancelButtonText = this.$t('rent_processing.campaign.cancel_button')
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('rent_processing.campaign.confirm_button')
    this.pBoxButtonConfirmation = () => this.completeCampaignPermission(true)
    this.pBoxButtonCancel = () => this.completeCampaignPermission(false)
  },
  cleanPBoxProps() {
    this.showPBoxCancelButton = false
    this.showDemonstration = false
    this.pBoxCancelButtonText = ''
    this.showPBoxConfirmButton = false
    this.pBoxConfirmButtonText = ''
    this.showCheckboxPBox = false
    this.pBoxCheckBoxText = ''
    this.showPBoxSpinner = false
    this.pBoxSecondsToClose = 0
    this.pBoxShowLink = false
    this.handleOpenTable = null
    this.showTags = false
    this.pBoxCheckBoxValue = null
    this.pBoxButtonConfirmation = null
    this.pBoxButtonCancel = null
    this.pBoxClose = null
  }
}
