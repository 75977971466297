import axios from 'axios'

const LogoutComponent = {
  name: 'logout',
  components: {},
  mounted: function () {
    this.logout()
  },
  methods: {
    logout: function () {
      localStorage.removeItem('token')
      sessionStorage.removeItem('campaign')
      sessionStorage.removeItem('campaignUrl')
      localStorage.removeItem('terminal_token')
      axios.defaults.headers.common['Authorization'] = null
      this.$router.push({
        name: 'login_social', // Update to login-social later
        params: {}
      })
    }
  }
}

export default LogoutComponent
