export const TAKE_BATTERY_SUCCESS = 'take_battery_success'
export const TAKE_BATTERY_ERROR = 'take_battery_error'
export const VALIDATION_SUCCESS = 19
export const VALIDATION_CODE_LENGTH = 6
export const CABLE_TYPE_LIGHTNING = 0
export const CABLE_TYPE_MICRO = 1
export const CABLE_TYPE_USBC = 2
export const SUCCESS_MSG = 'Cartão cadastrado com sucesso'
export const FAIL_MSG = 'An error occured'
export const BIG_BOX_COMPONENT_LIST = [
    { id: 3, name: 'CreditCardRegistration', reference: 'creditCardRegistration' },
    { id: 4, name: 'DefaultTile', reference: 'deleteCreditCard' },
    { id: 5, name: 'SelectCableType', reference: 'selectCableType' },
    { id: 6, name: 'TokenSelection', reference: 'tokenSelection' },
    { id: 7, name: 'Map', reference: 'map' },
    { id: 7, name: 'Demonstration', reference: 'demonstration' }
  ]
export const MINI_BOX_COMPONENT_LIST = [
    { id: 1, name: 'UserDefaults', reference: 'map' },
    { id: 2, name: 'UserDefaults', reference: 'priceTable' },
    { id: 3, name: 'UserDefaults', reference: 'userCreditCard' },
    { id: 4, name: 'UserDefaults', reference: 'cableType' },
    { id: 5, name: 'UserDefaults', reference: 'token' }
  ]
