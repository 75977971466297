<template>
  <div
    v-if="showEvaluationBox"
    class="select-card d-flex flex-column"
  >
    <h3
      class="page-title text-center pt-2"
      v-text="$t('home.take.battery_not_removed')"
    />

    <div v-if="showThanksMessage">
      <p
        class="d-flex flex-wrap justify-content-center"
        style="margin: 10px"
      >
        {{ this.$t('evaluation_box.text') }} 
      </p>
    </div>
    <div v-else>
      <p
        class="d-flex flex-wrap justify-content-center"
        style="margin: 10px"
      >
        <button
          v-for="(tag, index) in evaluationTags"
          :key="index"
          size="sm"
          :class="[tag.active ? 'errorTag active' : 'errorTag']"
          @click="toggleTagState(tag.id)"
        >
          <div style="white-space: nowrap; font-size: small">
            {{ tag.tag }}
          </div>
        </button>
      </p>
    </div>

    <div class="tile-actions d-flex justify-content-end">
      <BButton
        v-if="!showThanksMessage"
        class="cancel-button my-2"
        variant="secondary"
        @click="cancelAction"
        v-text="$t('home.take.skip')"
      />
      <BButton
        v-if="!showThanksMessage"
        class="button my-2"
        variant="primary"
        @click="saveTags"
        v-text="$t('home.take.send')"
      />
    </div>
  </div>
</template>

<script>
import '@/main.scss'
import API from '@/services/api'
import { TAKE_BATTERY_ERROR } from '@/constants.js'
import * as Sentry from '@sentry/vue'


export default {
  name: 'EvaluationBox',
  props: {
    transactionId: {
      type: Number,
      default: null
    }
  },
  data: function () {
    return {
      checkBoxInitValue: false,
      evaluationTags: [],
      showEvaluationBox: true,
      showThanksMessage: false
    }
  },
  mounted() {
    this.getTags(TAKE_BATTERY_ERROR)
  },
  methods: {
    cancelAction() {
      this.showEvaluationBox = false
      this.$emit('close')
    },
    async getTags(status) {
      this.evaluationTags = null

      let response = null
      try {
        response = await API.get(`evaluation/${status}/tags`)
      } catch (error) {
        console.log(error)
        Sentry.captureException(error)
      }

      if (!response || !response.data || !response.data.tags) {
        return {}
      }
      
      this.uniqueTag = response.data.unique_tag
      this.evaluationTags = response.data.tags.map((tag) => {
        return {
          id: tag.id,
          tag: sessionStorage.getItem('lang') === 'en' ? tag.tag_en : tag.tag,
          active: false
        }
      })
    },

    toggleTagState(tagId) {
      this.evaluationTags = this.evaluationTags.map((tag) => {
        if (tag.id === tagId) {
          return {
            id: tag.id,
            tag: tag.tag,
            active: this.uniqueTag ? true : !tag.active
          }
        } else {
          return {
            id: tag.id,
            tag: tag.tag,
            active: this.uniqueTag ? false : tag.active
          }
        }
      })
    },
    async saveTags() {
      if (!this.transactionId) {
        console.log('Transaction id not defined')
        this.cancelAction()
        return
      }

      const tagIds = []
      if (this.evaluationTags && this.evaluationTags.length > 0) {
        this.evaluationTags.forEach((tag) => {
          if (tag.active === true) {
            tagIds.push(tag.id)
          }
        })
      }
      

      try {
        const params = {
          transaction_id: this.transactionId,
          tag_ids: tagIds
        }

        let response = null
        try {
          response = await API.post('/transaction/evaluation', params)
        } catch (error) {
          console.log(error)
          Sentry.captureException(error)
          this.cancelAction()
        }
        

        if (!response) {
          console.log('Problem saving evaluation')
          this.cancelAction()
          return
        }

        if (response.data.success) {
          this.showThanks()
          return
        }
      } catch (error) {
        console.error('Error saving evaluation:', error)
        this.cancelAction()
        return
      }

      this.cancelAction()
    },
    showThanks() {
      this.showThanksMessage = true
      setTimeout(() => {
        this.cancelAction()
      }, 3000)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.tile-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.flex-grow-1 {
  flex-grow: 1;
  display: flex;
  align-items: left;
  justify-content: center;
}
.page-text {
  text-align: justify;
  margin: 0;
  max-width: 90%;
}
#loading-spinner {
  display: block;
  color: #f58a48;
}

.errorTag {
  border: 1px solid $color-energy-gray;
  border-radius: 35px;
  background-color: transparent;
  color: #000000;
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  margin-right: 10px;
  width: fit-content;
}

.errorTag.active {
  border-color: #fff;
  background-color: $color-energy-blue;
  color: #fff;
}
</style>
