<section id="login-back" class="inner-section">
  <img src="@/assets/images/logo.png" alt="" id="logo" />
  <div id="login-div" style="background: #259df2">
    <div class="vue-template" style="text-align: center">
      <div style="text-align: center">
        <div>
          <h3 class="navbar-text mb-0" v-text="$t('login.sign_in')"></h3>
        </div>

        <div class="error_container">
          <p v-if="error" class="errors">
            <b>{{ error }} </b>
          </p>
        </div>

        <div class="validation_boxes">
          <div
            class="mb-0"
            v-for="(_, index) in code"
            style="display: inline-flex; gap: 3px"
            :key="index"
          >
            <input
              :id="'id_' + index"
              maxlength="VALIDATION_CODE_LENGTH"
              required
              v-model="code[index]"
              type="text"
              autocapitalize="none"
              @input="onInput(index, $event)"
              @keydown="onKeyDown(index, $event)"
              @focus="onFocus($event)"
              @click="onClick($event)"
              ref="inputs"
            />
          </div>
        </div>
        <h6 class="mt-0 mb-5" v-text="$t('login.verify_mail')"></h6>

        <div style="width: 250px; margin: auto">
          <button
            id="validate_button"
            v-on:click="validateCode"
            class="btn btn-primary btn-lg btn-block"
          >
            Validar
          </button>
        </div>

        <div class="mt-3" style="width: 250px; margin: auto">
          <button v-on:click="routeSignUp" class="btn btn-primary btn-lg btn-block">
            Reenviar email
          </button>
        </div>
      </div>

      <div class="mt-3 clearfix" id="account_options">
        <p class="register-account text-center mt-2 mb-4">
          <span>
            <router-link
              class="link"
              to="/login-social"
              v-text="$t('login.register_social')"
            ></router-link>
          </span>
        </p>
      </div>
    </div>
  </div>
</section>
