<section id="forgot-password" class="inner-section" v-bind:style="{ background: style.bg }">
  <div class="container" v-bind:style="{ background: style.secondary_bg }">
    <img src="../../assets/images/logo_dark.png" alt="" id="logo" />

    <div class="vue-template">
      <div id="account_confirmation">
        <h3 v-text="$t('confirm_account.welcome')"></h3>
        <p v-text="$t(message)"></p>
        <p class="error_msg" v-if="error" v-html="error"></p>
      </div>
      <footer>
        <ul>
          <li>
            <router-link to="/login" v-text="$t('sign_up.already_have_account')"></router-link>
          </li>
          <li>
            <a href="./policy.html" v-text="$t('sing_up.user_terms')"></a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</section>
