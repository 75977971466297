export default function getPropsBigComponents(component) {
  switch (component) {
    case 'priceTable':
      return {
        showPriceTable: this.showPriceTable
      }
    case 'creditCardRegistration':
      return {
        showCreditCardRegistration:
          this.user && (this.user.default_card === null || this.user.default_card === undefined),
        user: this.user
      }
    case 'deleteCreditCard':
      return {
        showDefaultTile:
          this.user && this.user.default_card && this.user.default_card.number.length === 4,
        title: this.$t('home.take.delete_title'),
        text: this.$t('home.take.delete_text') + ' ' + this.user.default_card.number,
        showConfirmButton: true,
        showCancelButton: true,
        showSpinner: this.showSpinner,
        confirmButtonText: this.$t('default_tile.credit_card.exclude'),
        cancelButtonText: this.$t('home.take.cancel')
      }
    case 'selectCableType':
      return {
        cableType: this.cableType,
        cableSelectionEnable: true,
        userId: this.user.id
      }
    case 'tokenSelection':
      return {
        showTokenSelection: true,
        confirmRent: this.confirmRent,
        cableType: this.cableType,
        allowEdit: this.editTokenSelection,
        saveInvalidTransaction: this.saveInvalidTransaction,
        token: this.token,
        userId: this.user.id
      }
    default:
      return console.log('Component not found')
  }
}
