export default function getEventsMiniComponents(component) {
  switch (component) {
    case 'map':
      return {
        clicked: () => {
          this.mapArrowDown = false
          let params = {}
          if (this.user && this.user.id) {
            params['user_id'] = this.user.id
          }
          if (this.transaction) {
            params['transaction_id'] = this.transaction.id
          }
          return this.$router.push({ name: 'maps' })
        }
      }
    case 'priceTable':
      return {
        clicked: () => {
          return this.scrollToPriceTable()
        }
      }
    case 'userCreditCard':
      return {
        clicked: () => {
          if (this.selectedBigBoxComponent && this.selectedBigBoxComponent.reference === 'deleteCreditCard') {
            return this.selectBigBoxComponent()
          }
          return this.selectBigBoxComponent('deleteCreditCard')
        }
      }
    case 'cableType':
      return {
        clicked: () => {
          this.cableType = null
          sessionStorage.removeItem('cableType')
          this.selectBigBoxComponent()
          return
        }
      }
    case 'token':
      return {
        clicked: () => {
          this.transactionId = null
          this.pointName = null
        }
      }
    default:
      return console.log('Component not found')
  }
}
