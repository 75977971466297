<template>
  <div class="mytabs">
    <div>

    </div>
    <div class="select-card card-title">
      <a class="btn-close-history" @click="$router.go(-1)">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </a>
      <div class="title" v-text="$t('history.rent')"></div>
    </div>
    <div>
      <div class="text-center">
        <BSpinner id="loading-spinner" v-if="spinner" />
      </div>
      <div class="select-card subscribe-margin" v-for="result in results" :key="result.id">

        <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket" class="fa fa-rotate-90" />
            </div>
            <div class="flex-history-description">
              <div class="flex-history-title">
                Retirada:
              </div>
              <div class="flex-history-text">
                {{ result.take_point.name }}, {{ result.take_point.address }}
              </div>
              <div class="flex-history-text">
                {{ result.take_date }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-clock-rotate-left" class="fa mirror" />
            </div>
            <div class="flex-history-description">
              <div class="flex-history-title">
                Tempo com a bateria:
              </div>
              <div class="flex-history-text">
                {{ this.rentTime(result) }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-arrow-up-from-bracket" class="fa" />
            </div>
            <div class="flex-history-description">
              <div class="flex-history-title">
                Devolução:
              </div>
              <div class="flex-history-text">
                {{  this.returnBattery(result) }}
              </div>
              <div v-if="result.status !== 2" class="flex-history-text">
                {{ result.return_date }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-dollar-sign" class="fa" />
            </div>
            <div class="flex-history-description">
              <div v-if="result.status !== 2" class="flex-history-title">
                Pagamento:
              </div>
              <div v-else class="flex-history-title">
                Reserva de saldo:
              </div>
              <span>
                <span class="flex-history-text" :class="{'line-through': isRefunded(result)}" style="display: inline-block">
                  {{ price (result)}}
                </span>&nbsp
                <span class="flex-history-text" style="display: inline-block" v-if="isRefunded(result)">
                  {{ ' (Valor estornado)' }}
                </span>
              </span>
              
              <div class="flex-history-text">
                {{ paymentType (result) }} 
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="my-3">
          <div class='flex-history-element'>
            <div class="flex-history-icon">
              <font-awesome-icon icon="fa-solid fa-star" class="fa" />
            </div>
            <div class="flex-history-description">
              <div class="flex-history-title">
                Classificação:
              </div>
              <div v-if="result.rating" class="flex-history-star">
                <div v-for="star in 5" :key="star">
                  <font-awesome-icon icon="fa-solid fa-star"
                    :class="[star <= result.rating ? 'fa-minor flex-history-star-on' : 'fa-minor flex-history-star-off']"
                    class="fa " />
                </div>
              </div>
              <div v-else class="flex-history-text">
                {{ 'Sem classificação' }}
              </div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
    <div class="text-center">
      <b-button v-if="next_page" @click="get_history" variant="danger">
        <div v-text="$t('history.load_more')"></div>
      </b-button>
    </div>
  </div>
</template>

<script>

import API from '@/services/api'
import sendGtag from '@/services/sendGtags'

const PRE_PAYMENY_APROVED = 2
const PRE_PAYMENT_REFUNDED = 6

const GTAG_HISTORY = 'history'

export default {
  name: 'history',
  components: {},
  props: [],
  data() {
    return {
      results: [],
      history_a: [],
      next_page: null,
      spinner: true,
      history_blocks: [],
      lineThrough: false
    }
  },
  mounted: async function () {
    let c = sessionStorage.getItem('campaign')
    if (c) {
      this.campaign = c
    }

    sendGtag(GTAG_HISTORY, {}, true)

    await this.get_history()
  },
  methods: {
    isRefunded (result) {
      return result.pre_payments[result.pre_payments.length - 1] && result.pre_payments[result.pre_payments.length - 1].status && result.pre_payments[result.pre_payments.length - 1].status === PRE_PAYMENT_REFUNDED && result.payment && result.payment.status === null
    },
    padTo2Digits: function (num) {
      return num.toString().padStart(2, '0')
    },
    formatDate: function (date) {
      return (
        [
          this.padTo2Digits(date.getDate()),
          date.toLocaleString('default', { month: 'short' }),
          date.getFullYear()
        ].join(' de ') +
        ' ' +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes())
        ].join(':')
      )
    },
    get_history: async function async() {
      let url = 'transaction/history'
      if (this.results.length > 0 && this.next_page) {
        url = this.next_page
      }
      const response = await API.get(url)

      try {
        this.history_a = response.data
        this.next_page = this.history_a.meta.next_page
        let results_temp = this.history_a.results
        if (results_temp && results_temp.length > 0) {
          let results = this.modify_api_response(results_temp)
          if (this.results.length > 0 && this.next_page) {
            this.results.push(...results)
          } else {
            this.results = results
          }
        }
        this.spinner = false

      } catch (error) {
        this.handleError(error)
      }
    },
    modify_api_response: function (arr) {
      arr.forEach((element) => {
        element.take_date = this.formatDate(new Date(element.take_time * 1000))
        if (element.plan_name == '') {
          element.no_plan = true
        }
        if (element.return_time === 0) {
          element.elapsed_time = 'DEVOLUÇÃO PENDENTE'
        } else {
          element.return_date = this.formatDate(new Date(element.return_time * 1000))
          var time = element.elapsed_time
          var hours = Math.floor(time / 3600)
          var min = Math.floor((time - hours * 3600) / 60)

          var hours_pad = this.padTo2Digits(hours)
          var min_pad = this.padTo2Digits(min)

          var hours_str = hours_pad != '00' ? hours_pad + ' h ' + 'e ' : ''
          var min_str = min_pad != '00' ? min_pad + ' min' + ' e ' : ''
          element.elapsed_time = hours_str + min_str
        }
      })
      return arr
    },
    handleError: function (error) {
      if (error.status && error.status === 401) {
        this.$parent.log_out()
      } else {
        console.log(error)
      }
    },
    updateLastTransaction: function () {
      API.get('transaction/history?page=1&items_per_page=1').then(
        (response) => {
          if (response.data) {
            this.lastTransaction = response.data.last_transaction

            let now = new Date().getTime()
            let past = this.lastTransaction.take_time * 1000
            let freeTime = this.lastTransaction.free_time * 1000

            if (now - past > freeTime) {
              this.lastTransaction['on_free_time'] = false
            } else {
              this.lastTransaction['on_free_time'] = true
            }
          }
        },
        (error) => {
          this.handleError(error)
        }
      )
    },
    price (result) {
      if (result.free_time_type === "Signature"){
        return "Grátis pela assinatura"
      }
      if (result.credit_card.number === null) {
        return "Grátis" 
      }
      if (result.status === PRE_PAYMENY_APROVED) {
        return result.pre_payments[result.pre_payments.length - 1].amount
          ?  "R$ " + parseFloat(result.pre_payments[result.pre_payments.length - 1].amount).toFixed(2)
          : "Grátis" 
      }
      if (result.payment && result.payment.amount && result.payment.status === "Approved") {
        return "R$ " + parseFloat(result.payment.amount).toFixed(2) 
      }
      if (result.pre_payments[result.pre_payments.length - 1] && result.pre_payments[result.pre_payments.length - 1].status && result.pre_payments[result.pre_payments.length - 1].status === PRE_PAYMENT_REFUNDED) {
        return "R$ " + parseFloat(result.pre_payments[result.pre_payments.length - 1].amount).toFixed(2)
      }
    },
    paymentType (result) {
      if (result.credit_card.number) {
        return`Cartão de crédito final: ${result.credit_card.number}`
      }
    },
    rentTime(result) {
      return result.status === PRE_PAYMENY_APROVED
        ? result.cron_time
        : result.rent_time.all
    },
    returnBattery(result) {
      return result.status !== PRE_PAYMENY_APROVED
        ? `${result.return_point.name }, ${ result.return_point.address }`
        : "Bateria com o cliente"
    }
  }
}


</script>

<style scoped lang="scss">
@import '@/main.scss';

@font-face {
  font-family: main;
  src: url('@/assets/fonts/main.otf');
}

.card-title {
  margin: auto;
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
  flex: 1.6;
}

.btn-close-history {
  color: #666;
  font-size: 30px;
  z-index: 99999;
  cursor: pointer;
  margin-left: 10px;
  flex: 1;
}

#loading-spinner {
  color: $color-energy-orange !important;
}

.mytabs {
  overflow: scroll;
}

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.flex-history-element {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: flex-start;
}

.flex-history-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  max-width: 80px;
}

.flex-history-description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: black;
}

.flex-history-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px !important;
  padding: 0px !important;
}

.flex-history-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: gray;
  font-size: large;
  margin: 0px !important;
  padding: 0px !important;
}

.flex-history-star {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px
}

.flex-history-star-on {
  color: rgb(107, 107, 107);
}

.flex-history-star-off {
  color: rgb(202, 202, 202);
}

.mirror {
  transform: scaleX(-1);
}

.subscribe-margin {
  margin-bottom: 15px !important;
}

.fa {
  font-size: 30px;
  margin-bottom: 1px;
  margin-right: 2px;
}

.fa-minor {
  font-size: 30px;
}

// .rating-text {
//   position: absolute;
//   font-size: 24px;
//   /* ajuste o tamanho da fonte para o seu gosto */
//   z-index: 2;
//   color: white;
// }

.line-through {
  text-decoration: line-through;
}

</style>