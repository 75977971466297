import * as Sentry from '@sentry/vue'

function sendGtagOnetimeBySession(eventName) {
    let gtagsSended = sessionStorage.getItem('gtag')
    if (gtagsSended) {
        gtagsSended = JSON.parse(gtagsSended)
    }
    if (gtagsSended && gtagsSended.includes(eventName)) {
        return false
    }
    if (gtagsSended) {
        gtagsSended.push(eventName)
    } else {
        gtagsSended = [eventName]
    }
    gtagsSended = JSON.stringify(gtagsSended)
    sessionStorage.setItem('gtag', gtagsSended)
    return true
}

function getPointId() {
  const pointIdTokenSelection = sessionStorage.getItem('point_id_token_selection')
  if (pointIdTokenSelection) {
      return { id: pointIdTokenSelection, type: 'token_selection' }
  }
  const pointIdQr = sessionStorage.getItem('point_id_qr')
  if (pointIdQr) {
      return { id: pointIdQr, type: 'qr_code' }
  }
  const pointIdGeolocation = sessionStorage.getItem('point_id_geolocation')
  if (pointIdGeolocation) {
      return { id: pointIdGeolocation, type: 'geolocation' }
  }
  return { id: null, type: null }
}

export default function sendGtag(tagName, params = {}, sendOneTimeOnly = false) {
	try {
        if (sendOneTimeOnly) {
            let first_time = null
            first_time = sendGtagOnetimeBySession(tagName)
            if (!first_time) {
                return
            }
        }
		
        const { id, type } = getPointId()
        if (id && type) {
        params['point_id'] = id,
        params['point_id_capture_method'] = type
        }
        window.gtag('event', tagName, params)
	} catch (error) {
		console.log(error)
		Sentry.captureException(`Error sending gtag ${tagName}: ${JSON.stringify(error)}`)
	}
}