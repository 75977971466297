import API from '@/services/api'

const CampaignComponent = {
  name: 'campaign',
  mounted: function () {
    const path = window.location.pathname
    this.$session.set('campaignUrl', path)

    this.setCampaign(path).then(() => {
      if (this.$session.has('token')) {
        this.$router.push('/home')
      } else {
        this.$router.push('/login-social')
      }
    })
  },
  methods: {
    setCampaign(path) {
      return new Promise((resolve) => {
        const paths = path.split('/')
        const campaign = paths[1]
        const token = paths[2] !== undefined ? paths[2] : null

        API.get('campaign/' + campaign).then(
          (response) => {
            if (response.data && response.data.campaign) {
              this.$session.set('tokenTerminal', token)
              this.$session.set('campaign', response.data.campaign)
            }

            resolve()
          },
          (error) => {
            this.$session.set('campaign', null)
            Promise.reject(error)
          }
        )
      })
    }
  }
}

export default CampaignComponent
