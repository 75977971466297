<template>
  <div>
    v<span class="px-2">{{ version }}</span>
  </div>
</template>

<script>
import { VERSION } from '@/config'

export default {
  data: function () {
    return {
      version: VERSION
    }
  }
}
</script>

<style scoped>
div {
  font-size: 12px;
  color: #333;
  text-align: right;
}

div span {
  font-size: 0.9em;
}
</style>
