<template>
  
    <div
      v-if="showPriceTable"
      class="width-adjust"
      :class="[
        noShadow
          ? 'select-card no-shadow d-flex flex-column'
          : 'select-card d-flex flex-column'
      ]"
    >
      <!-- Tile Title -->
      <h3
        class="page-title text-center pt-2"
        v-text="$t('home.take.table_title')"
      />

      <!-- Optional Text -->
      <!-- Image transition -->
      <div
        v-if="plan_daily && plan_4_hours"
        key="image"
        style="margin: 18px"
      >
        <span>{{ $t('home.take.text_table_redraw') }}</span>
        <div style="margin-top: 12px; font-weight: 500">
          <span>{{ $t('home.take.text_table_parks') }}</span>
        </div>
        <div>
          <span>{{ $t('home.take.text_table_charges_only') }}</span>
          <span class="stay-same-line">
            <span class="amount-description-color">{{ plan_daily.description }}</span>
            <span class="amount-description-color">s</span>
          </span>
          <span>{{ $t('home.take.of') }}</span>
          <span class="stay-same-line">
            <span class="amount-description-color">R$</span>
            <span class="amount-description-color">{{ plan_daily.amount.reais }}</span>
            <span class="amount-description-color">,</span>
            <span class="amount-description-color">{{ plan_daily.amount.centavos }}</span> </span>
        </div>
        <div style="margin-top: 12px; font-weight: 500">
          <span>{{ $t('home.take.text_table_other_locations') }}</span>
        </div>
        <div>
          <span>{{ $t('home.take.text_table_ischarged') }}</span>
          <span class="stay-same-line">
            <span class="amount-description-color">R$</span>
            <span class="amount-description-color">{{ plan_4_hours.amount.reais }}</span>
            <span class="amount-description-color">,</span>
            <span class="amount-description-color">{{ plan_4_hours.amount.centavos }}</span>
          </span>
          <span>{{ $t('home.take.by') }}</span>
          <span class="amount-description-color">{{ plan_4_hours.description }}</span>

          <span>{{ $t('home.take.text_table_stay') }}</span>
          <span>{{ $t('home.take.text_table_after_period') }}</span>
          <span class="stay-same-line">
            <span class="amount-description-color">{{ plan_daily.description }}</span>
            <span class="amount-description-color">s</span>
          </span>
          <span>{{ $t('home.take.of') }}</span>
          <span class="stay-same-line">
            <span class="amount-description-color">R$</span>
            <span class="amount-description-color">{{ plan_daily.amount.reais }}</span>
            <span class="amount-description-color">,</span>
            <span class="amount-description-color">{{ plan_daily.amount.centavos }}</span>.
          </span>
        </div>
        <p class="footnote">
          {{ $t('home.take.table_footnote') }}
        </p>
      </div>
      <!-- Action Buttons -->
      <!-- Image transition -->
      <div
        v-if="true"
        key="text"
        class="tile-actions d-flex justify-content-end"
      >
        <BButton
          class="button my-2"
          variant="primary"
          @click="closePriceTable"
        >
          {{  this.$t('price_table.dismiss') }}
        </BButton>
      </div>
    </div>
</template>

<script>
import '@/main.scss'
import API from '@/services/api'

export default {
  name: 'PriceTable',
  props: {
    showPriceTable: {
      type: Boolean,
      default: false
    },
    noShadow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      plans: null,
      plan_4_hours: null,
      plan_daily: null,
      mobile_api_url: import.meta.env.VITE_MOBILE_API
    }
  },
  created() {
    this.getPlans()
  },
  methods: {
    closePriceTable() {
      this.$emit('close')
    },
    async getPlans() {
      try {
        const response = await API.get(this.mobile_api_url + 'plan')
        if (response.data) {
          this.plans = response.data
          this.plan_4_hours = this.plans.on_demand.prices.find(
            (price) => price.internal_ref === 'four_hours'
          )
          this.plan_daily = this.plans.on_demand.prices.find(
            (price) => price.internal_ref === 'daily'
          )
          return
        }
        console.log(`Failed to get plan ${JSON.stringify(response)}, calling #getPlans()`)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../main.scss';

.stay-same-line {
  overflow: hidden;
  white-space: nowrap;
}

.amount-description-color {
  color: $color-energy-orange;
  display: inline-block;
}

.footnote {
  font-size: 12px;
  margin-top: 13px;
}

.width-adjust {
  width: 100% !important;

  @media (max-width: 768px) {
    width: 95% !important;
  }
}

</style>
