<section id="sign-up" class="inner-section">
  <img src="@/assets/images/logo.png" alt="" id="logo" />
  <h3 class="subtitle text-uppercase" v-text="$t('sign_up.sign_up')"></h3>

  <div id="login-div" style="background: #259df2">
    <div class="vue-template">
      <div class="error_container">
        <p v-if="message" class="errors">
          <b v-text="message"></b>
        </p>

        <div v-if="errors.length" class="errors">
          <ul>
            <li v-for="error in errors" class="p-1">
              <div v-for="listOfErrors in error">
                <ol>
                  <li v-for="keyError in listOfErrors" v-text="$t(keyError)"></li>
                </ol>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <form class="py-5">
        <div class="ml-auto mx-auto form-group" style="width: 270px; font-size: 1.15rem">
          <input
            id="email_registration_input"
            type="email"
            v-model="input.email"
            class="form-control form-control-lg"
            v-bind:placeholder="$t('sign_up.type_email')"
            style="width: 270px"
          />
        </div>

        <div class="mb-1 mt-1 form-group" id="user_terms_control" style="margin: auto">
          <input
            id="user_terms_checkbox"
            type="checkbox"
            v-model="input.userTerms"
            style="margin: 5px"
          />
          <span>
            <label
              for="user_terms"
              class="ml-1 control-label"
              v-text="$t('sign_up.agree_user_terms')"
            ></label>
          </span>
          <br />

          <span>
            <u>
              <a
                class="ml-1 text-white control-label"
                href="./policy.html"
                v-text="$t('sign_up.user_terms')"
              ></a>
            </u>
          </span>
        </div>

        <button
          id="sign_up_button"
          v-on:click="signUp"
          class="m-auto btn btn-lg btn-primary btn-block mt-4 text-uppercase"
          style="width: 270px"
          v-text="$t('sign_up.submit')"
        ></button>
      </form>
    </div>

    <div class="clearfix" id="account_options">
      <p class="register-account text-center mt-4 mb-1">
        <router-link
          to="/login-social"
          v-text="$t('sign_up.already_have_social_account')"
        ></router-link>
      </p>
    </div>

    <div class="clearfix" id="account_options">
      <p class="register-account text-center mt-1 mb-2">
        <span>
          <span v-text="$t('login.login_with_code')"></span>
          <router-link to="/code-validation" v-text="$t('login.click_here')"></router-link>
        </span>
      </p>
    </div>
  </div>

  <div class="link-text">
    <a href="#" class="no-underline" v-on:click.prevent="handleShowPriceTableModal(true)">
      <span style="color: #f6f6f6">
        <span class="link-map" v-text="$t('home.take.price_table')"></span>
        <font-awesome-icon
          style="margin-left: 5px"
          class="btn-icon-map"
          icon="fa-solid fa-money-bill-alt"
          size="lg"
        />
      </span>
    </a>
  </div>
  

  <div class="link-text">
    <router-link :to="{ path: `maps` }" class="no-underline">
      <span style="color: #f6f6f6">
        <span class="link-map" v-text="$t('login.find_us')"></span>
        <font-awesome-icon class="btn-icon-map" icon="fa-solid fa-map-location-dot" />
      </span>
    </router-link>
  </div>

  <PriceTableModal
    v-if="showPriceTableModal"
    :showPriceTableModal="showPriceTableModal"
    @hidePriceTableModal="handleShowPriceTableModal(false)"
  ></PriceTableModal>

  <AppVersion class="app-version" />
</section>