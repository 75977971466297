<template>
  <div 
    v-if="user && user.is_user_on_demand"
    class="select-card d-flex flex-column"
  >
    <div v-if="lastTransaction.on_free_time">
      <h6
        class="checkout-bold"
        v-text="$t('home.return.free_time')"
      />
      <p class="checkout">
        {{ lastTransaction.next_payment.cron }}
      </p>
    </div>
    <div
      v-if="!lastTransaction.on_free_time && (!campaign || (campaign && !campaign.no_payment))"
    >
      <h6
        class="checkout-bold"
        v-text="$t('home.return.time')"
      />
      <p class="checkout">
        {{ lastTransaction.cron_time }}
      </p>
      <h6
        class="checkout-bold"
        v-text="$t('home.return.price')"
      />
      <p class="checkout">
        R${{ lastTransaction.price_in_the_moment.reais }},{{
          lastTransaction.price_in_the_moment.centavos
        }}
      </p>
    </div>
    <div v-if="!lastTransaction.on_free_time && campaign && campaign.no_payment">
      <h6
        class="checkout"
        v-text="$t('home.return.free_time_over')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RentCheckout',
  props: {
    user: {
      type: Object,
      default: null
    },
    lastTransaction: {
      type: Object,
      default: null
    },
    campaign: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

#cron-card {
  width: 80%;
  margin: 30px auto 10px auto;
  background-color: transparent !important;
  border: 1px solid $color-energy-orange !important;
}

.checkout-bold {
  display: flex;
  font-weight: 700;
  justify-content: center;
  margin: 25px 0px 5px 0px;
}

.checkout {
  display: flex;
  justify-content: center;
  margin: 10px 0px 30px 0px;
}
</style>
