import axios from 'axios'

export default async function getPointByToken(terminalToken) {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_MOBILE_API}points/${terminalToken}/name`
      )
      return response.data
    } catch (error) {
      console.error(error)
      return null
    }
  }