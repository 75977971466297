<template>
  <Transition
    name="fade-box"
    mode="out-in"
  >
    <div v-if="cableSelectionEnable">
      <b-card class="select-card">
        <Transition
          name="fade-element"
          mode="out-in"
        >
          <!-- Image transition -->
          <div
            v-if="cableSelectionEnable"
            key="image"
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <p
              class="page-title h4 text-uppercase"
              v-text="$t('home.take.take_title')"
            />
            <p
              class="page-text fw-normal"
              v-text="$t('home.take.take_info')"
            />
          </div>
        </Transition>
        <Transition
          name="fade-element"
          mode="out-in"
        >
          <!-- Image transition -->
          <BContainer
            key="text"
            class="bv-example-row mt-4"
          >
            <BRow>
              <BCol
                cols="4"
                class="cable-type"
              >
                <img
                  id="cable_lightning_button"
                  class="cable-img"
                  :class="{ 'blinking-border': cableTypeSelected === CABLE_TYPE_LIGHTNING}"
                  src="@/assets/images/cables/apple_2.png"
                  alt="imagem conector Apple Lightning"
                  @click="selectCable(CABLE_TYPE_LIGHTNING)"
                >
                <p class="h6 page-text">
                  Apple Lightning
                </p>
              </BCol>
              <BCol
                cols="4"
                class="cable-type"
              >
                <img
                  id="cable_micro_usb_button"
                  class="cable-img"
                  :class="{ 'blinking-border': cableTypeSelected === CABLE_TYPE_MICRO }"
                  src="@/assets/images/cables/micro_2.png"
                  alt="imagem conector Micro USB"
                  @click="selectCable(CABLE_TYPE_MICRO)"
                >
                <p class="h6 page-text">
                  Micro USB
                </p>
              </BCol>
              <BCol
                cols="4"
                class="cable-type"
              >
                <img
                  id="cable_type_c_button"
                  class="cable-img"
                  :class="{ 'blinking-border': cableTypeSelected === CABLE_TYPE_USBC }"
                  src="@/assets/images/cables/c_2.png"
                  alt="imagem conector USB tipo C"
                  @click="selectCable(CABLE_TYPE_USBC)"
                >
                <p class="h6 page-text">
                  USB-C
                </p>
              </BCol>
            </BRow>
          </BContainer>
        </Transition>
      </b-card>
    </div>
  </Transition>
</template>

<script>
import { CABLE_TYPE_LIGHTNING, CABLE_TYPE_MICRO, CABLE_TYPE_USBC } from '@/constants.js'
import sendGtag from '@/services/sendGtags'

const GTAG_CABLE_TYPE = 'cable_type_selection'

export default {
  name: 'SelectCableType',
  props: {
    cableSelectionEnable: {
      type: Boolean,
      default: false
    },
    cableType: {
      type: Number,
      default: null
    },
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      cableTypeSelected: null,
      isBlinking: false,
      CABLE_TYPE_LIGHTNING,
      CABLE_TYPE_MICRO,
      CABLE_TYPE_USBC
    }
  },
  methods: {
    selectCable(cable) {
      this.cableTypeSelected = cable
      let params = {
          cable_type: cable,
          user_id: this.userId
        }
      sendGtag(GTAG_CABLE_TYPE, params)
      sessionStorage.setItem('cableType', cable)
      setTimeout(() => {
        this.$emit('cableType', cable);
      }, 1000);
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.cable-img {
  padding: 3px;
  width: 100%;
  margin-bottom: 20px;
  opacity: 0.70;
}

.cable-type {
  text-align: center !important;
  font-size: 12px;
}

.blinking-border {
  border: 2px solid transparent; 
  animation: blink 0.25s 2.5; 
}


@keyframes blink {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.7;
  }
}
</style>
