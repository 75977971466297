<template>
  <!-- Box transition -->
  <div
    v-if="showUserDefaults"
    class="indv-card select-card"
    :style="backgroundStyle"
  >
    <div class="bv-example-row">
      <BRow>
        <!-- Left Image -->
        <BCol
          key="image"
          cols="3"
          class="d-flex justify-content-center align-items-center"
        >
          <slot name="left-content" />
        </BCol>

        <!-- Middle Text -->
        <BCol
          key="text"
          cols="6"
          class="d-flex justify-content-left align-items-center"
        >
          <div class="fw-normal text-lighter text-container">
            <p
              class="page-text no-margin"
              v-text="mainText"
            />
            <p
              class="page-title fw-bold d-inline-block text-lighter no-margin"
              v-text="subText"
            />
            <p
              class="terms-text d-inline-block no-margin"
              v-text="termsText"
            />
          </div>
        </BCol>

        <!-- Right Button -->
        <BCol
          v-if="allowEdit"
          key="text"
          cols="2"
          class="btn d-flex justify-content-end"
        >
          <button
            class="btn d-flex justify-content-end align-items-center"
            @click="handleClick"
          >
            <font-awesome-icon
              v-if="arrowDown"
              class="btn-icon-arrow fa-2x"
              icon="fa-solid fa-chevron-down"
            />
            <font-awesome-icon
              v-else
              class="btn-icon-arrow fa-2x"
              icon="fa-solid fa-chevron-up"
            />
          </button>
        </BCol>
      </BRow>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultTileHome',
  props: {
    mainText: {
      type: String,
      required: false,
      default: ''
    },
    subText: {
      type: String,
      required: false,
      default: ''
    },
    termsText: {
      type: String,
      required: false,
      default: ''
    },
    backgroundImage: {
      type: String,
      required: false,
      default: ''
    },
    showUserDefaults: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    arrowDown: {
      type: Boolean,
      default: true
    }
  },
  computed: {    
    backgroundStyle() {
      return this.backgroundImage ? { backgroundImage: `url(${this.backgroundImage})` } : {}
    }
  },
  methods: {
    handleClick() {
      this.$emit('clicked')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.indv-card {
  justify-content: center;
  height: 100px;
  margin: 5px auto !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px !important;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    margin: 10px auto !important;
    text-align: left;
    justify-content: center;
  }

  @media (max-width: 576px) {
    margin: 10px auto !important;
    text-align: left;
    justify-content: center;
  }
}

.text-container {
  padding: 0;
  margin: 0;
}

.btn-icon-arrow {
  margin: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.row > .col-4,
.row > .col-6,
.row > .col-2 {
  flex: 1 1 auto;
}

</style>
