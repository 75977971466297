<template>
  <div
    toggleable="lg"
    class="navbar select-card d-flex"
    style="
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    "
  >
    <BNavbarToggle target="nav-collapse" />

    <div>
      <img
        id="logo"
        src="../assets/images/logo_dark.png"
        alt=""
        height="42"
      >
    </div>

    <div
      class="d-flex"
      style="justify-content: space-between"
    >
      <div v-if="!external && language === 'en'">
        <span class="change-lang mx-1" @click="set_lang('pt-BR')">en</span>
      </div>
      <div v-if="!external && language === 'pt-BR'">
        <span class="change-lang mx-1" @click="set_lang('en')">pt-br</span>
      </div>
      <a
        :href="$t('login.whatsapp_msg')"
        target="_blank"
        class="mx-1"
      >
        <font-awesome-icon
          class="whatsapp-icon"
          :icon="{ prefix: 'fab', iconName: 'whatsapp' }"
        />
      </a>
    </div>

    <BCollapse
      id="nav-collapse"
      is-nav
    >
      <BNavbarNav class="bg-light p-3 rounded">
        <div class="fw-bold">
          {{ name }}
        </div>
        <div class="small">
          {{ email }}
        </div>
        <hr>
        <BNavItem>
          <router-link to="/history">
            {{ $t('home.take.history') }}
          </router-link>
        </BNavItem>
        <BNavItem>
          <router-link to="/maps">
            {{ $t('home.take.maps_menu') }}
          </router-link>
        </BNavItem>
        <BNavItem>
          <a
            href="https://www.energy2go.com.br/eventos"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t('home.take.need_help') }}
          </a>
        </BNavItem>
        <BNavItem>
          <router-link to="/logout">
            {{ $t('home.take.logout') }}
          </router-link>
        </BNavItem>
        <AppVersion />
      </BNavbarNav>
    </BCollapse>
  </div>
</template>

<script>
import AppVersion from '@/components/AppVersion.vue'
import axios from 'axios'

export default {
  name: 'SideBar',
  components: {
    AppVersion
  },
  props: {
    name: {
      type: String,
      default: null
    },
    email: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      language: 'pt-BR',
      external: false
    }
  },
  mounted() {
    let lang = sessionStorage.getItem('lang')
    this.set_lang(lang)
  },
  methods: {
    set_lang: function (lang) {
      if (lang === undefined || lang === 'pt-BR') {
        sessionStorage.setItem('lang', 'pt-BR')
        this.$i18n.locale = 'pt'
        this.language = 'pt-BR'
        axios.defaults.headers.common['Accept-Language'] = 'pt-BR'
      } else if (lang === 'en') {
        sessionStorage.setItem('lang', 'en')
        this.$i18n.locale = 'en'
        this.language = 'en'
        axios.defaults.headers.common['Accept-Language'] = 'en'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';
</style>
