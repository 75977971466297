<section id="login-back" class="inner-section">
  <img src="@/assets/images/logo.png" alt="" id="logo" />
  <h3 class="subtitle text-uppercase" v-text="$t('login.title_rent')"></h3>
  <div id="login-div" style="background: #259df2" class="py-5">
    <div class="vue-template">
      <h3 class="navbar-text" v-text="$t('login.sign_in')"></h3>
      <div
        style="
          display: flex;
          width: 270px;
          flex-direction: column;
          flex-flow: space-around;
          align-items: center;
          align-content: space-around;
          margin: auto;
        "
      >
        <GoogleLogin 
          :button-config="{ size: 'large', width: '270'}"
          :callback="callback"
          one-tap="true"
          prompt
        />
        <HFaceBookLogin
          class="btn-facebook mb-3"
          v-slot="fbLogin"
          app-id="{{ import.meta.env.VITE_FACEBOOK_ID }}"
          @onSuccess="onFacebookSignInSuccess"
          @onFailure="onFacebookError"
          scope="email,public_profile"
          fields="id,name,email"
        >
          <div class="floating-box" style="display: inline-block">
            <img class="btn-facebook-img" :src="fb_icon" />
          </div>
          <div class="floating-box" style="display: inline-block; align-content: space-between">
            <span
              @click="fbLogin.initFBLogin"
              id="facebook_registration_button"
              class="btn-facebook-text"
              >{{ $t('login.facebook_btn_text') }}</span
            >
          </div>
        </HFaceBookLogin>
      </div>
    </div>
    <div class="clearfix" id="account_options">
      <p id="sign_up_link" class="register-account text-center mt-3">
        <router-link to="/sign-up" v-text="$t('login.register_with_email')"></router-link>
      </p>

      <div class="clearfix" id="account_options">
        <p class="register-account text-center mt-0 mb-2">
          <span>
            <span v-text="$t('login.login_with_code')"></span>
            <router-link to="/code-validation" v-text="$t('login.click_here')"></router-link>
          </span>
        </p>
      </div>
    </div>
  </div>

  <div class="link-text">
    <a href="#" class="no-underline" v-on:click.prevent="handleShowPriceTableModal(true)">
      <span style="color: #f6f6f6">
        <span class="link-map" v-text="$t('home.take.price_table')"></span>
        <font-awesome-icon
          style="margin-left: 5px"
          class="btn-icon-map"
          icon="fa-solid fa-money-bill-alt"
          size="lg"
        />
      </span>
    </a>
  </div>
  

  <div class="link-text">
    <router-link :to="{ path: `maps` }" class="no-underline">
      <span style="color: #f6f6f6">
        <span class="link-map" v-text="$t('login.find_us')"></span>
        <font-awesome-icon class="btn-icon-map" icon="fa-solid fa-map-location-dot" />
      </span>
    </router-link>
  </div>

  <PriceTableModal
    v-if="showPriceTableModal"
    :showPriceTableModal="showPriceTableModal"
    @hidePriceTableModal="handleShowPriceTableModal(false)"
  ></PriceTableModal>

  <AppVersion class="app-version" />
</section>
