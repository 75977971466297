import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    confirm_account: {
      welcome: 'Account confirmation',
      confirmation_successful: 'Your account has succesfuly confirmed',
      confirmation_failed: 'An error ocurred in your account confirmation',
      waiting: 'Waiting for account confirmation...',
      unspected_error: 'Unspected error occurred'
    },
    login: {
      battery_almost_over: 'BATTERY ALMOST OVER?!',
      fast_login: 'LOG IN FOR QUICK RENT',
      or: 'OR',
      create_account: 'CREATE NEW ACCOUNT',
      sign_in: 'Log in with your account',
      sign_in_button: 'Login',
      email_address: 'Email Address',
      password: 'Password',
      forgot_password: 'Forgot Password?',
      dont_have_account: "Don't have an account?",
      register_email: 'Sing up with email',
      register_social: 'Sing up with social network',
      register_with_email: 'Access with email',
      login_with_code: 'Already have a code?',
      click_here: 'Click Here!',
      facebook_btn_text: 'Continue with Facebook',
      locale: 'en_US',
      whatsapp_msg:
        'https://api.whatsapp.com/send/?phone=5521998445033&text=Hi!%20I%20need%20help%20-%20E2G',
      find_us: 'Find other spots',
      title_rent: 'Rent a Power Bank',
      verify_mail: '*Check your inbox mail for the code. If not found, check on the spam folder'
    },
    sign_up: {
      submit: 'Access',
      sign_up: 'Email Access',
      email_address: 'Email',
      password: 'Password',
      password_confirmation: 'Password Confirmation',
      agree_user_terms: ' I agree with the user terms',
      type_email: 'Type your email',
      user_terms: 'User terms',
      already_have_account: 'Already have an account with your email?',
      already_have_social_account: 'Sing up with social network',
      mandatory_user_terms: 'You must agree with the user terms',
      password_doesnt_match: 'The password confirmation does not match the password',
      invalid_email: 'Invalid email',
      required_email: 'Email is required',
      email_verification:
        'Verify your email to access your account. If not found, check on the spam folder',
      title_open_mail_modal: 'Validation'
    },
    forgot_password: {
      forgot_password: 'Forgot Password?',
      email: 'Email',
      remember: 'Submit',
      success: 'We have sent you an email with a link to reset your password',
      failed: 'We could not find a user with this email address',
      redirect_msg: 'You will be redirected to the login page in 5 seconds',
      invalid_email: 'Invalid email',
      required_email: 'Email is required'
    },
    map: {
      place_open: 'Open',
      place_closed: 'Closed',
      available_bateries: 'available bateries',
      available_slots: 'available slots',
      close_hour: 'Closes at',
      open_hour: 'Opens at',
      hour: 'en',
      my_location: 'My Location',
      phone: 'SMARTPHONE',
      ebike: 'E-BIKE',
      all: 'ALL',
      terminal_type: 'Terminals for: '
    },
    home: {
      take: {
        add_card: 'REGISTER YOUR CARD',
        no_charge: 'Register your credit card to collect a battery.',
        card_number: 'Credit Card Number',
        valid_through: 'Valid Through',
        name: 'Name on card',
        save_credit_card: 'Save credit card',
        take_title: 'Select cable type',
        take_info: 'it is already built into the battery.',
        cable_type: 'Choose the Cable Type',
        qr_code_title_1: 'Locate the QR Code on the front of the terminal',
        qr_code_title_2: 'Locate the QR Code in the terminal',
        qr_code_text: 'Scan or enter the 4 digits below the QR code',
        scan_qr: 'Scan',
        scan_qr2: 'QR code',
        type_token: 'Or enter the Token Located on Terminal Display',
        terminal_token: '####',
        take_battery: 'Take Battery',
        agreed: 'When you TAKE BATTERY you agree with the prices showed ',
        price_table: 'Price List',
        credit_cards: 'CREDIT CARDS',
        manage: 'Manage your payment methods',
        standard: 'Standard',
        delete: '',
        delete_title: 'Delete Credit Card',
        delete_text: 'Delete card ending in ',
        delete_cable_title: 'Delete Cable',
        cancel: 'CANCEL',
        confirm: 'CONFIRM',
        need_help: 'Contact Us for Events',
        selected_card: 'Your the card',
        ok: 'OK',
        just_ok: 'OK',
        standard_title: 'Standard Credit Card',
        standard_text: 'Set as Standard the credit card ending in ',
        set_standart_credit_card_not_possible:
          'An error occurred while updating the default payment method. Please try again',
        slot: 'PUSH, then PULL the battery indicated by the blinking LED',
        charger: "Charger's App must be indicating that the battery is ready to go",
        verify_terminal: 'Checking Terminal',
        register_card: 'Registering Credit Card',
        far: 'We notice that the requested terminal is far from your device. Are you sure you want to continue?',
        credit_reservation_1: 'A reservation of ',
        credit_reservation_2:
          ' will be held on your credit card. In some cases, when free time is offered we will free your credit card hold if you return the battery before the end of this free time.',
        dont_ask: "Don't ask again",
        campaign:
          'By using the promotional free time you agree to receive information from the sponsor.',
        no_free: 'Rent without the sponsored free time',
        update: 'Updating Permissions',
        on_demand: 'RENT ON DEMAND',
        subscription: 'SUBSCRIPTION PLANS',
        understand: 'I UNDERSTOOD',
        free_time_title: 'Next Rent',
        free: 'free',
        sponsored_by: 'Sponsored By',
        at: 'at',
        only_at: "To access all Terminal's Location, ",
        click_here: 'click here!',
        other_terminals: 'Find other terminals',
        logout: 'Logout',
        history: 'History',
        logout_2: 'LOGOUT',
        renew: 'Renew ',
        events: 'CONTACT US FOR EVENTS',
        maps_menu: 'Terminals Map',
        here: 'here',
        table_footnote: '*You are renting only one power bank and its energy may not be available for the whole renting time.',
        by: ' by ',
        table_title: 'Charged Values',
        of: ' of ',
        text_table_redraw: 'Withdraw your power bank at:',
        text_table_parks: 'Parks and Stadiums and Bossa Nova Mall:',
        qr_scan_fail: 'Token not found, please scan again or enter the token manually.',
        qr_scan_fail_backend: 'Token nulo, leitura do qrcode falhou',
        text_table_charges_only: '- Charged only ',
        text_table_other_locations: 'Other locations:',
        text_table_ischarged: '- Charged ',
        text_table_after_period: 'After this period is charged ',
        text_table_stay: ' of stay with the battery. ',
        feedback_thanks: 'THANKS FOR THE RESPONSE!',
        close: 'Close',
        send: 'SEND',
        skip: 'SKIP',
        what_happened: 'What happened?',
        customer_response_1: "I didn't know which battery to remove.",
        customer_response_2: "I tried pulling it out, but the battery wouldn't come out.",
        customer_response_3: 'I gave up on removing the battery',
        battery_not_removed: 'Battery was not removed.',
        inform_token: 'Token must be informed.',
        token_contain: 'Token must contain only numbers or only letters.',
        token_size: 'Token must contain 4 numbers or letters.',
        user_card: 'You selected the card:',
        user_cable_notice_1: 'You have selected the cable: ',
        user_cable_notice_2: '*it is already built into the battery',
        card_ending: 'Ending',
        terminal_offline_title: 'TERMINAL OFFLINE',
        terminal_offline_text: 'The terminal is offline, the battery cannot be removed.'
      },
      demonstration: {
        title: "Take the battery",
        text: "First PUSH the battery until it unlocks, then PULL as shown in the figure below."
      },
      return: {
        success: 'BATTERY WITHDRAWED SUCCESSFULLY!',
        fail_title: 'BATTERY NOT REMOVED',
        fail_text: 'The battery was not removed.',
        free_time: 'Free Time Remaining',
        free_time_over: 'Your free time is up! Please return the battery to any of our terminals.',
        time: 'Time using battery',
        price: 'Current Price',
        check_out: 'I want to see the',
        price_table: 'Price List'
      }
    },
    history: {
      rent: 'Rent',
      plan: 'Plan: ',
      load_more: 'Load More',
      pending: 'RETURN PENDING'
    },
    price_table: {
      dismiss: 'DISMISS'
    },
    token_selection: {
      main_text: 'Rent Location'
    },
    rent_processing: {
      start: {
        text: 'Starting battery rental'
      },
      verify: {
        text: 'Verifying battery station'
      },
      cancel: {
        text: 'Rent cancelled by user, due to distance from terminal',
        button: 'UNDERSTOOD'
      },
      payment: {
        text: 'Processing payment'
      },
      error: {
        title: 'Error',
        text: 'Error to remove battery'
      },
      campaign: {
        cancel_button: 'RENT WITHOUT FREE',
        confirm_button: 'USE FREE TIME'
      }
    },
    evaluation_box: {
      text: 'Thanks for the answer. Try again'
    },
    default_tile: {
      credit_card: {
        exclude: 'EXCLUDE'
      }
    }
  },
  pt: {
    confirm_account: {
      welcome: 'Confirmação de conta',
      waiting: 'Verificando conta...',
      confirmation_successful: 'Sua conta foi confirmada com sucesso',
      confirmation_failed: 'Ocorreu um erro ao confirmar a sua conta',
      unspected_error: 'Ocorreu um erro inesperado'
    },
    login: {
      battery_almost_over: 'BATERIA QUASE ACABANDO?!',
      fast_login: 'FAÇA LOGIN PARA RETIRADA RÁPIDA',
      or: 'OU',
      create_account: 'CRIAR UMA CONTA NOVCRIAR UMA CONTA NOVA',
      sign_in: 'Entre na sua conta',
      sign_in_button: 'Entrar',
      email_address: 'Email',
      password: 'Senha',
      forgot_password: 'Esqueceu a senha?',
      dont_have_account: 'Ainda não tem uma conta?',
      register_email: 'Cadastrar com email',
      register_social: 'Cadastrar com rede social',
      register_with_email: 'Acessar pelo email',
      login_with_code: 'Já possui o código? ',
      click_here: 'Clique aqui',
      facebook_btn_text: 'Continuar com o Facebook',
      locale: 'pt_BR',
      whatsapp_msg:
        'https://api.whatsapp.com/send/?phone=5521998445033&text=Oi!%20Preciso%20de%20ajuda%20-%20E2G',
      find_us: 'Encontre outros pontos',
      title_rent: 'Alugue um carregador portátil',
      verify_mail:
        '*Verifique sua caixa de entrada do email para ver se o código foi enviado. Se não encontrado, verifique a pasta de spam.'
    },
    sign_up: {
      submit: 'Acessar',
      sign_up: 'Acessar por email',
      email_address: 'Email',
      type_email: 'Digite seu e-mail',
      password: 'Senha',
      password_confirmation: 'Confirme a senha',
      agree_user_terms: 'Concordo com os termos de uso',
      user_terms: 'Termos de uso',
      already_have_account: 'Já tem uma conta?',
      already_have_social_account: 'Cadastrar com rede social',
      mandatory_user_terms: 'Você deve concordar com os termos de uso',
      password_doesnt_match: 'As senhas não conferem',
      invalid_email: 'Email inválido',
      required_email: 'Email obrigatório',
      email_verification:
        'Verifique seu email para acessar sua conta. Se não encontrado, verifique a pasta de spam.',
      title_open_mail_modal: 'Validação'
    },
    forgot_password: {
      forgot_password: 'Esqueceu a senha?',
      email: 'Email',
      remember: 'Enviar',
      success: 'Enviamos um email com um link para você redefinir sua senha',
      failed: 'Não encontramos nenhum usuário com este email',
      redirect_msg: 'Você será redirecionado para a página de login em 5 segundos',
      invalid_email: 'Email inválido',
      required_email: 'Email obrigatório'
    },
    map: {
      place_open: 'Aberto',
      place_closed: 'Fechado',
      available_bateries: 'baterias disponíveis',
      available_slots: 'vagas disponíveis',
      close_hour: 'Fecha às',
      open_hour: 'Abre às',
      hour: 'em',
      my_location: 'Minha Localização',
      phone: 'SMARTPHONE',
      ebike: 'E-BIKE',
      all: 'TODOS',
      terminal_type: 'Terminais para: '
    },
    home: {
      take: {
        add_card: 'CADASTRE SEU CARTÃO',
        no_charge: 'Cadastre seu cartão de crédito para retirar uma bateria portátil.',
        card_number: 'Número do Cartão',
        valid_through: 'Válido até',
        name: 'Nome no cartão',
        save_credit_card: 'Salvar cartão',
        take_title: 'Selecione o tipo de cabo',
        take_info: 'Ele já está embutido na bateria',
        cable_type: 'Escolha o Tipo de Cabo',
        qr_code_title_1: 'Localize o QR Code na frente do terminal',
        qr_code_title_2: 'Localize o QR Code no terminal',
        qr_code_text: 'Escaneie ou insira os 4 digitos abaixo do QR code',
        scan_qr: 'Escanear',
        scan_qr2: 'QR code',
        type_token: 'Ou digite o código de 4 dígitos abaixo do QR Code na frente do terminal:',
        terminal_token: '####',
        take_battery: 'Retirar Bateria',
        agreed: 'Ao Retirar Bateria, você concordará com os valores exibidos na ',
        price_table: 'Tabela de preços',
        credit_cards: 'CARTÕES DE CRÉDITO',
        manage: 'Gerencie seus métodos de pagamento',
        standard: 'Padrão',
        delete: '',
        delete_title: 'Excluir Cartão de Crédito',
        delete_text: 'Excluir cartão terminando em ',
        delete_cable_title: 'Excluir Cabo',
        cancel: 'CANCELAR',
        confirm: 'CONFIRMAR',
        need_help: 'Contate-nos para eventos',
        selected_card: 'Você selecionou o cartão',
        ok: 'OK',
        just_ok: 'OK',
        standard_title: 'Cartão de Crédito Padrão',
        standard_text: 'Definir como padrão o cartão de crédito terminando em ',
        set_standart_credit_card_not_possible:
          'Ocorreu um erro ao atualizar o método de pagamento padrão. Tente novamente',
        slot: 'EMPURRE, depois PUXE a bateria indicada pelo LED piscante',
        charger: 'O App do Carregador deve estar indicando que a bateria está pronta para uso',
        verify_terminal: 'Verificando Terminal',
        register_card: 'Cadastrando Cartão de Crédito',
        far: 'Notamos que o terminal solicitado está distante do seu dispositivo. Você tem certeza de que deseja continuar?',
        credit_reservation:
          'Será efetuada uma reserva de saldo em seu cartão de acordo com a tabela de preços. Se a bateria não for retirada, a reserva será automaticamente cancelada.',
        dont_ask: 'Não perguntar novamente',
        campaign:
          'Ao utilizar o tempo gratuito promocional, você concordará em receber informações do patrocinador.',
        no_free: 'Alugar sem o tempo gratuito patrocinado',
        update: 'Atualizando Permissões',
        on_demand: 'ALUGAR SOB DEMANDA',
        subscription: 'PLANOS DE ASSINATURA',
        understand: 'ENTENDI',
        free_time_title: 'Próxima Locação',
        free: 'grátis',
        sponsored_by: 'Patrocinado Por',
        at: 'em',
        only_at: 'Para acessar todos os Locais do Terminal, ',
        click_here: 'clique aqui!',
        other_terminals: 'Encontre outros terminais',
        logout: 'Sair',
        history: 'Histórico',
        logout_2: 'SAIR',
        renew: 'Renovar ',
        events: 'ENTRE EM CONTATO CONOSCO PARA EVENTOS',
        maps_menu: 'Mapa de Pontos',
        here: 'aqui',
        table_footnote: '*O aluguel é para apenas uma bateria portátil e sua carga pode não durar por todo o período do aluguel.',
        by: ' por ',
        table_title: 'Valores Cobrados',
        of: ' de ',
        text_table_redraw: 'Retire sua bateria portátil* em:',
        text_table_parks: 'Parques, Estádios e Bossa Nova Mall:',
        text_table_charges_only: '- São cobradas apenas ',
        text_table_other_locations: 'Demais pontos:',
        text_table_ischarged: '- É cobrado ',
        text_table_stay: ' de permanência com a bateria',
        text_table_after_period: ', após este período são cobradas ',
        feedback_thanks: 'OBRIGADA PELA REPOSTA!',
        close: 'Fechar',
        send: 'ENVIAR',
        skip: 'PULAR',
        what_happened: 'O que aconteceu?',
        customer_response_1: 'Não soube qual bateria retirar.',
        customer_response_2: 'Tentei puxar, mas a bateria não saiu.',
        customer_response_3: 'Desisti de retirar a bateria',
        battery_not_removed: 'Bateria não foi removida.',
        inform_token: 'Token deve ser informado.',
        token_contain: 'Token deve conter apenas números ou apenas letras.',
        token_size: 'Token deve conter 4 números ou letras.',
        qr_scan_fail: 'Token não encontrado, escanear novamente ou informar o token manualmente.',
        qr_scan_fail_backend: 'Token nulo, leitura do qrcode falhou',
        user_card: 'Você selecionou o cartão: ',
        user_cable_notice_1: 'Você selecionou o cabo: ',
        user_cable_notice_2: '*ele já vem embutido na bateria',
        card_ending: 'Final',
        terminal_offline_title: 'TERMINAL OFFLINE',
        terminal_offline_text: 'O terminal está offline, a bateria não pode ser retirada.',

      },
      demonstration: {
        title: "Retire a bateria",
        text: "PUXE a bateria conforme a figura."
      },
      return: {
        success: 'BATERIA RETIRADA COM SUCESSO!',
        fail_title: 'BATERIA NÃO RETIRADA',
        fail_text: 'A bateria não foi retirada.',
        free_time: 'Tempo Gratuito Restante',
        free_time_over:
          'Seu tempo gratuito acabou! Por favor, devolva a bateria em qualquer um de nossos terminais.',
        time: 'Tempo usando a bateria',
        price: 'Preço Atual',
        check_out: 'Quero ver a ',
        price_table: 'Tabela de Preços'
      }
    },
    history: {
      rent: 'Locação',
      plan: 'Plano: ',
      load_more: 'Carregar Mais',
      pending: 'DEVOLUÇÃO PENDENTE'
    },
    price_table: {
      dismiss: 'DISPENSAR'
    },
    token_selection: {
      main_text: 'Local da Retirada'
    },
    rent_processing: {
      start: {
        text: 'Iniciando retirada de bateria'
      },
      verify: {
        text: 'Verificando estação de bateria'
      },
      cancel: {
        text: 'Aluguel cancelado pelo usuário, devido à distante do terminal',
        button: 'ENTENDI'
      },
      payment: {
        text: 'Processando pagamento'
      },
      error: {
        title: 'Erro',
        text: 'Erro ao retirar bateria'
      },
      campaign: {
        cancel_button: 'RETIRAR SEM GRATUIDADE',
        confirm_button: 'USAR TEMPO GRÁTIS'
      }
    },
    evaluation_box: {
      text: 'Obrigado pela resposta. Tente novamente'
    },
    default_tile: {
      credit_card: {
        exclude: 'EXCLUIR'
      }
    }
  }
}

const i18n = createI18n({
  locale: 'pt', // set locale
  fallbackLocale: 'pt', // set fallback locale
  messages // set locale messages
})

export default i18n
